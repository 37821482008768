import React, { useEffect, useState } from 'react';
import { FormikProvider } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import { useStateContext } from '../Contexts/ContextProvider';

const Contact = ({ prevStep, nextStep, firstStep }) => {
   const {
      setFirstName,
      setLastName,
      setEmail,
      setPhone,
      firstName,
      lastName,
      email,
      phone,
      specialRequest,
      setSpecialRequest,
   } = useStateContext();

   let values = {
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      email: email,
      special_request: specialRequest,
   };
   const [formValues, setFormValues] = useState(values);
   const [formErrors, setFormErrors] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);

   const validate = (values) => {
      const errors = {};
      const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      const regex_phone =
         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

      if (!values.phone) {
         errors.phone = 'Required';
      }
      if (!regex_phone.test(values.phone)) {
         errors.phone = 'Phone number is not valid';
      }

      if (!values.first_name) {
         errors.first_name = 'Required';
      }

      return errors;
   };
   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));
      setIsSubmit(true);
   };

   useEffect(() => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
         //save value
         setFirstName(formValues.first_name);
         setLastName(formValues.last_name || '');
         setEmail(formValues.email || '');
         setPhone(formValues.phone);
         setSpecialRequest(formValues.special_request || '');
         nextStep();
      }
   }, [formErrors, isSubmit]);

   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='center'
               direction='row'
            >
               <Grid item xs={6}>
                  <TextField
                     id='phone'
                     name='phone'
                     label='Mobile Phone'
                     fullWidth
                     value={formValues.phone}
                     onChange={handleChange}
                     helperText={formErrors.phone}
                     required
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id='email'
                     name='email'
                     label='Email'
                     fullWidth
                     value={formValues.email}
                     onChange={handleChange}
                     helperText={formErrors.email}
                  />
               </Grid>

               <Grid item xs={6}>
                  <TextField
                     id='first_name'
                     name='first_name'
                     label='First Name'
                     fullWidth
                     value={formValues.first_name}
                     onChange={handleChange}
                     required
                     helperText={formErrors.first_name}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id='last_name'
                     name='last_name'
                     label='Last Name'
                     fullWidth
                     value={formValues.last_name}
                     onChange={handleChange}
                     helperText={formErrors.last_name}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     id='special_request'
                     name='special_request'
                     label='If you have any special request, please let us know here.'
                     fullWidth
                     multiline
                     maxRows={4}
                     value={formValues.special_request}
                     onChange={handleChange}
                     helperText={formErrors.special_request}
                  />
               </Grid>
               <Grid
                  container
                  spacing={10}
                  direction='row'
                  justifyContent='flex-end'
                  marginTop={1}
               >
                  <Grid item xs={1}>
                     <Button onClick={firstStep}>Back</Button>
                  </Grid>
                  <Grid item xs={4}>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
};

export default Contact;
