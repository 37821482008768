import './App.css';
import BookAppointment from './components/BookAppointment';
import { Routes, Route } from 'react-router-dom';
import Feedback from './Feedback';
import NotFound from './NotFound';
import Feedback2 from './Feedback2';
import GiftCard from './GiftCard';

function App() {
   return (
      <Routes>
         <Route path="*" element={<NotFound />} />
         <Route path='/' element={<BookAppointment />} />
         <Route path="/feedback/:id" element={<Feedback />} />
         <Route path="/feedback2" element={<Feedback2 />} />
         <Route path="/giftcard" element={<GiftCard />} />
      </Routes>
   );
}

export default App;
