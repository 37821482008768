import {
   Avatar,
   Box,
   Button,
   Checkbox,
   Container,
   CssBaseline,
   FormControlLabel,
   Grid,
   TextField,
   Typography,
} from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

function Copyright() {
  return (
     <Typography variant='body2' color='text.secondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://potomacspa.spaxzs.com'>
           potomacspa.spaxzs.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
     </Typography>
  );
}
const GiftCard = () => {
  return (
    <Container component='main' maxWidth='xs'>
         <CssBaseline />
         <Box
            sx={{
               marginTop: 8,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <img
               src='/potomac_spa_logo.png'
               alt='logo'
               width={'60px'}
               height={'60px'}
            />
            <Typography component='h1' variant='h5'>
               Potomac Massage Spa
            </Typography>
            
         </Box>
         <Copyright sx={{ mt: 10 }} />
      </Container>
  )
}

export default GiftCard