import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

const fetchSchedules = async ({start_date,service_id,store_id}) => {
   let start_time = "08:00:00";
   
   if (typeof start_date !== "string") {
      start_date = moment.utc(start_date).tz('America/New_York').format('YYYY-MM-DD')
   }
   
   if (moment().format('YYYY-MM-DD') === start_date) {
      start_time = moment.utc().tz('America/New_York').format("HH:mm:ss");
   }
   const { data } = await axios.get(
      process.env.REACT_APP_API_SERVER +
         `/api/schedule/get?start_date=${start_date}&start_time=${start_time}&service_id=${service_id}&store_id=${store_id}`
   );
   return data;
};

export function useSchedules({start_date,service_id,store_id}) {
   return useQuery(['schedules', start_date,service_id,store_id], () => fetchSchedules({start_date,service_id,store_id}));
}
