import {
   Button,
   CssBaseline,
   Grid,
   TextareaAutosize,
   Typography,
} from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStateContext } from './Contexts/ContextProvider';

function Copyright() {
   return (
      <Typography variant='body2' color='text.secondary' align='center' sx={{mt: 5}}>
         {'Copyright © '}
         <Link color='inherit' href='https://potomacspa.spaxzs.com'>
            potomacspa.spaxzs.com
         </Link>{' '}
         {new Date().getFullYear()}
         {'.'}
      </Typography>
   );
}

const Feedback2 = () => {
   const [isCopied, setIsCopied] = useState(false);
   const {feedback} = useStateContext();

   async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(feedback)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        // setTimeout(() => {
        //   setIsCopied(false);
        // }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleGoogleClick = () => {
    window.open('https://www.google.com/search?q=Potomac Massage Spa');
  }


   return (
      <Container component='main' maxWidth='xs'>
         <CssBaseline />
         <Box
            sx={{
               marginTop: 5,
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <img
               src='/potomac_spa_logo.png'
               alt='logo'
               width={'60px'}
               height={'60px'}
            />
            <Typography component='h1' variant='h5'>
               Potomac Massage Spa
            </Typography>
            <Box sx={{ mt: 3 }}>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Typography component='h1' variant='body2'>
                        Thanks for your feedback!
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography component='h1' variant='body2'>
                        Step 1: Simply copy the comment you wrote.
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{padding: 4}}>
                     <input type='text' value={feedback} readOnly />
                     <button onClick={handleCopyClick}>
                        <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                     </button>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography component='h1' variant='body2'>
                       2. And share your review on Google.
                     </Typography>
                  </Grid>
                 
                    <Grid item xs={12}>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            sx={{ mb: 2 }}
                            onClick={handleGoogleClick}
                        >Share to Google </Button>
                    </Grid>
                    <Grid item xs={12}>
                     <Typography component='h1' variant='body2'>
                        Thank you.
                     </Typography>
                  </Grid>
               </Grid>
            </Box>
         </Box>
         <Copyright />
      </Container>
   );
};

export default Feedback2;
